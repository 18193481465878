var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FrInputLayout',{attrs:{"id":_vm.id,"name":_vm.name,"description":_vm.description,"errors":_vm.errors,"floating-label":_vm.floatingLabel,"is-html":_vm.isHtml,"label":_vm.label,"validation":_vm.validation,"validation-immediate":_vm.validationImmediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var labelHeight = ref.labelHeight;
return [(_vm.fieldType === 'number')?_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.inputValue),expression:"inputValue",modifiers:{"number":true}}],ref:"input",class:[
        'form-control',
        {
          'polyfill-placeholder': _vm.floatLabels,
          'is-invalid': _vm.errorMessages && _vm.errorMessages.length,
          'text-truncate' : _vm.copy,
        }
      ],style:(labelHeight && {height: (labelHeight + 2) + 'px', 'padding-top': _vm.floatLabels ? (labelHeight - 27) + 'px' : (labelHeight - 35) + 'px'}),attrs:{"type":"number","data-vv-as":_vm.label,"disabled":_vm.disabled,"id":_vm.id,"name":_vm.name,"placeholder":_vm.floatingLabel ? _vm.label : _vm.placeholder,"readonly":_vm.readonly,"data-testid":_vm.testid},domProps:{"value":(_vm.inputValue)},on:{"animationstart":function($event){_vm.floatingLabel && _vm.animationStart},"input":function($event){if($event.target.composing){ return; }_vm.inputValue=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}):((_vm.fieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:[
        'form-control',
        {
          'polyfill-placeholder': _vm.floatLabels,
          'is-invalid': _vm.errorMessages && _vm.errorMessages.length,
          'text-truncate' : _vm.copy,
        }
      ],style:(labelHeight && {height: (labelHeight + 2) + 'px', 'padding-top': _vm.floatLabels ? (labelHeight - 27) + 'px' : (labelHeight - 35) + 'px'}),attrs:{"data-vv-as":_vm.label,"disabled":_vm.disabled,"id":_vm.id,"name":_vm.name,"placeholder":_vm.floatingLabel ? _vm.getTranslation(_vm.label) : _vm.placeholder,"readonly":_vm.readonly,"autocomplete":_vm.$attrs.autocomplete,"data-testid":_vm.testid,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"input":function (evt){ return _vm.inputValue=evt.target.value; },"animationstart":function($event){_vm.floatingLabel && _vm.animationStart},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.fieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:[
        'form-control',
        {
          'polyfill-placeholder': _vm.floatLabels,
          'is-invalid': _vm.errorMessages && _vm.errorMessages.length,
          'text-truncate' : _vm.copy,
        }
      ],style:(labelHeight && {height: (labelHeight + 2) + 'px', 'padding-top': _vm.floatLabels ? (labelHeight - 27) + 'px' : (labelHeight - 35) + 'px'}),attrs:{"data-vv-as":_vm.label,"disabled":_vm.disabled,"id":_vm.id,"name":_vm.name,"placeholder":_vm.floatingLabel ? _vm.getTranslation(_vm.label) : _vm.placeholder,"readonly":_vm.readonly,"autocomplete":_vm.$attrs.autocomplete,"data-testid":_vm.testid,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"input":function (evt){ return _vm.inputValue=evt.target.value; },"animationstart":function($event){_vm.floatingLabel && _vm.animationStart},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:[
        'form-control',
        {
          'polyfill-placeholder': _vm.floatLabels,
          'is-invalid': _vm.errorMessages && _vm.errorMessages.length,
          'text-truncate' : _vm.copy,
        }
      ],style:(labelHeight && {height: (labelHeight + 2) + 'px', 'padding-top': _vm.floatLabels ? (labelHeight - 27) + 'px' : (labelHeight - 35) + 'px'}),attrs:{"data-vv-as":_vm.label,"disabled":_vm.disabled,"id":_vm.id,"name":_vm.name,"placeholder":_vm.floatingLabel ? _vm.getTranslation(_vm.label) : _vm.placeholder,"readonly":_vm.readonly,"autocomplete":_vm.$attrs.autocomplete,"data-testid":_vm.testid,"type":_vm.fieldType},domProps:{"value":(_vm.inputValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},function (evt){ return _vm.inputValue=evt.target.value; }],"animationstart":function($event){_vm.floatingLabel && _vm.animationStart}}})]}},{key:"defaultButtons",fn:function(){return [(_vm.type === 'password')?_c('BInputGroupAppend',{staticClass:"fr-hide-input"},[_c('BButton',{class:[{'disabled': _vm.disabled}],attrs:{"name":"revealButton","aria-label":_vm.showPassword ? 'visibility_off' : 'visibility'},on:{"click":_vm.revealText,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')}}},[_c('FrIcon',{attrs:{"name":_vm.showPassword ? 'visibility_off' : 'visibility'}})],1)],1):_vm._e(),(_vm.copy)?_c('BInputGroupAppend',[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"id":("copyButton-" + _vm.value),"data-testid":("btn-copy-" + _vm.testid),"name":"copyButton"},on:{"click":function($event){$event.preventDefault();return _vm.copyValueToClipboard(_vm.value)}}},[_c('FrIcon',{attrs:{"name":"copy"}})],1),_c('BTooltip',{attrs:{"target":("copyButton-" + _vm.value),"placement":"top","triggers":"hover","title":_vm.$t('common.copy')}})],1):_vm._e()]},proxy:true},_vm._l((_vm.$scopedSlots),function(key,slotName){return {key:slotName,fn:function(slotData){return [_vm._t(slotName,null,null,slotData)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }